<template>
    <div class="text-center">
        <h1>Planning du jour</h1>
        <div class="text-left" style="max-width: 95%">
            <div v-for="event in formattedEvents" :key="event.key">
                <b-row>
                    <b-col cols="10" offset="1">
                        <hr class="mb-3 mt-3"/>
                    </b-col>
                </b-row>
                <p :style="{color: event.color}" class="mb-0">
                    <strong>{{ event.date.format('H[h]mm') }} :</strong>
                    {{ event.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {apiPath}       from '@/util/http';
    import {momentDates}   from '@/util/demande';
    import {isBureau}      from '@/util/auth';
    import alert           from '@/util/alert';
    import {textUsername}  from '@/util/user';
    import {numberSortAsc} from '@/util/sort';
    import colors          from "../../util/colors";

    export default {
        name: "MobileHome",
        data: () => ({
            calendarEvents: {starting: [], ending: []},
            emprunts: {starting: [], ending: []},
            evenements: {starting: [], ending: []},
            reservationsLocal: {starting: [], ending: []}
        }),
        computed: {
            formattedEvents() {
                let out = [];

                // All of this is terrifying, but in reality there will be few events each day, so the processing will be quick
                out = out.concat(this.calendarEvents.starting.map(e => this.formatCalendarEventStart(e)));
                out = out.concat(this.calendarEvents.ending.map(e => this.formatCalendarEventEnd(e)));
                out = out.concat(this.emprunts.starting.map(e => this.formatEmpruntStart(e)));
                out = out.concat(this.emprunts.ending.map(e => this.formatEmpruntEnd(e)));
                out = out.concat(this.evenements.starting.map(e => this.formatEvenementStart(e)));
                out = out.concat(this.evenements.ending.map(e => this.formatEvenementEnd(e)));
                out = out.concat(this.reservationsLocal.starting.map(e => this.formatReservationLocalStart(e)));
                out = out.concat(this.reservationsLocal.ending.map(e => this.formatReservationLocalEnd(e)));

                return out.sort((a, b) => numberSortAsc(a.date, b.date));
            }
        },
        methods: {
            formatCalendarEventStart: event => ({
                key: 'CalendarEventStart' + event.id,
                date: event.dateDebut,
                color: colors.permanences.border,
                description: event.nom ? ('Début - ' + event.nom) : 'Début de la permanence'
            }),
            formatCalendarEventEnd: event => ({
                key: 'CalendarEventEnd' + event.id,
                date: event.dateFin,
                color: colors.permanences.border,
                description: event.nom ? ('Fin - ' + event.nom) : 'Fin de la permanence'
            }),
            formatEmpruntStart: event => ({
                key: 'EmpruntStart' + event.id,
                date: event.dateDebut,
                color: colors.jeux.border,
                description: 'Départ de jeux / livres '
                    + '(JdR : ' + (event.jdrs.length ? event.jdrs.map(({nom}) => nom).join(', ') : 'aucun')
                    + ' ; Jeux : ' + (event.jeux.length ? event.jeux.map(({nom}) => nom).join(', ') : 'aucun')
                    + ' ; Livres : ' + (event.livres.length ? event.livres.map(({nom}) => nom).join(', ') : 'aucun')
                    + ') à destination de ' + textUsername(event.utilisateur)
            }),
            formatEmpruntEnd: event => ({
                key: 'EmpruntEnd' + event.id,
                date: event.dateFin,
                color: colors.jeux.border,
                description: 'Retour de jeux / livres (Jeux : '
                    + (event.jeux.length ? event.jeux.map(({nom}) => nom).join(', ') : 'aucun')
                    + ' ; Livres : ' + (event.livres.length ? event.livres.map(({nom}) => nom).join(', ') : 'aucun')
                    + ') de la part de ' + textUsername(event.utilisateur)
            }),
            formatEvenementStart: event => ({
                key: 'EvenementStart' + event.id,
                date: event.dateDebut,
                color: colors.evenements.border,
                description: "Début de l'événement " + event.nom
            }),
            formatEvenementEnd: event => ({
                key: 'EvenementEnd' + event.id,
                date: event.dateFin,
                color: colors.evenements.border,
                description: "Fin de l'événement " + event.nom
            }),
            formatReservationLocalStart: event => ({
                key: 'ReservationLocalStart' + event.id,
                date: event.dateDebut,
                color: colors.local.border,
                description: "Début de la réservation du local de " + textUsername(event.utilisateur)
            }),
            formatReservationLocalEnd: event => ({
                key: 'ReservationLocalEnd' + event.id,
                date: event.dateFin,
                color: colors.local.border,
                description: "Fin de la réservation du local de " + textUsername(event.utilisateur)
            }),
            loadCalendarEvents() {
                alert.loading();
                return this.axios.get(apiPath('calendar_events_start_end'))
                    .then(response => {
                        this.calendarEvents.starting = momentDates(response.data.starting);
                        this.calendarEvents.ending   = momentDates(response.data.ending);
                    })
                    .catch(() => this.$toaster.error('Impossible de charger les permanences'))
                    .finally(alert.stopLoading);
            },
            loadEmprunts() {
                if (isBureau()) {
                    alert.loading();
                    return this.axios.get(apiPath('emprunts_start_end'))
                        .then(response => {
                            this.emprunts.starting = momentDates(response.data.starting);
                            this.emprunts.ending   = momentDates(response.data.ending);
                        })
                        .catch(() => this.$toaster.error('Impossible de charger les emprunts'))
                        .finally(alert.stopLoading);
                }

                return Promise.resolve();
            },
            loadEvenements() {
                alert.loading();
                return this.axios.get(apiPath('evenements_start_end'))
                    .then(response => {
                        this.evenements.starting = momentDates(response.data.starting);
                        this.evenements.ending   = momentDates(response.data.ending);
                    })
                    .catch(() => this.$toaster.error('Impossible de charger les événements'))
                    .finally(alert.stopLoading);
            },
            loadReservationsLocal() {
                alert.loading();
                return this.axios.get(apiPath('reservations_local_start_end'))
                    .then(response => {
                        this.reservationsLocal.starting = momentDates(response.data.starting);
                        this.reservationsLocal.ending   = momentDates(response.data.ending);
                    })
                    .catch(() => this.$toaster.error('Impossible de charger les réservations du local'))
                    .finally(alert.stopLoading);
            },
            loadData() {
                this.loadCalendarEvents()
                    .then(() => this.loadEmprunts())
                    .then(() => this.loadEvenements())
                    .then(() => this.loadReservationsLocal());
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>